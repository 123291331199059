// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { Form, type InputRef, Layout, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InputBox } from '@imprivata-cloud/astra-ui-component-library';
import SetupStep from '../../components/SetupStep';
import SetupStepHeader from '../../components/SetupStepHeader';
import classes from './steps.module.less';
import {
  adminUiTenantIdParam,
  adminUsernameField,
  StorageKeys,
} from '../../constants/fieldNames';
import {
  useAdminCredsRequest,
  validateEmail,
  showInvalidEmailBanner,
} from '../../hooks/adminCredsHooks';
import type { AdminAuthParams } from '../../store/initialSetup/types';
import withTracing from '../../tracing/withTracing';
import { useTranslation } from 'react-i18next';
import storage from '../../utils/storage';
import backgroundImage from '../../assets/astra_setup_e.svg';
import { ADMIN_UI_URL } from '../../constants/services';
import {
  linkDeadSetupAlreadyComplete,
  dpaCheck,
} from '../../store/initialSetup/actions';
import DataProcessingAddendumModal from '../../components/DataProcessingAddendumModal';
import { dpaNoActionNeededSelector } from '../../store/initialSetup/selectors';
import { DPA_DOCUMENT_NAME } from '../../components/DataProcessingAddendum';

const { Content } = Layout;
const { Paragraph } = Typography;

const InitialAdminCredsStep: React.FC = function InitialAdminCredsStep() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<AdminAuthParams>();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const onSubmitClick = useCallback(() => form.submit(), [form]);
  const { pending, onSubmit } = useAdminCredsRequest();
  const tenantId = useMemo(() => storage.getItem(StorageKeys.TENANT_ID), []);
  const adminUiUrl = `${ADMIN_UI_URL}?${adminUiTenantIdParam}=${tenantId}`;
  const focusRef = useRef<InputRef>(null);

  const dpaNoActionNeeded = useSelector(dpaNoActionNeededSelector);
  const dpaAccepted = useRef<boolean>(false);
  const [visibleDpaModal, setVisibleDpaModal] = useState(false);
  const closeDpaModal = () => setVisibleDpaModal(false);
  const openDpaModal = () => setVisibleDpaModal(true);

  useEffect(() => {
    window.addEventListener('pageshow', event => {
      //Force to reload the page as page is always rendered from BFCache of browser automatically.
      if (event.persisted) {
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    focusRef.current?.focus();
    if (storage.isEmpty()) {
      dispatch(linkDeadSetupAlreadyComplete());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(dpaCheck.request({ consentDocumentName: DPA_DOCUMENT_NAME }));
  }, [dispatch]);

  return (
    <SetupStep
      data-testid="step-admin-creds"
      rightPartBackground={backgroundImage}
    >
      <DataProcessingAddendumModal
        visibleDpaModal={visibleDpaModal}
        closeDpaModal={closeDpaModal}
        onSubmitClick={onSubmitClick}
        dpaAccepted={dpaAccepted}
      />
      <SetupStepHeader title={t('steps.admin-creds.title-label')} />
      <Content>
        <Paragraph className="primary-body">
          {t('steps.admin-creds.label-1.paragraph-1')}
        </Paragraph>
        <Paragraph className="primary-body">
          {t('steps.admin-creds.label-1.paragraph-2')}
        </Paragraph>
        <Form
          name="adminCredsForm"
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={(values: AdminAuthParams) => {
            if (!validateEmail(values[adminUsernameField])) {
              showInvalidEmailBanner(t);
              return;
            }
            dpaNoActionNeeded || dpaAccepted.current
              ? onSubmit(values)
              : openDpaModal();
          }}
        >
          <Paragraph>
            <Form.Item
              required
              label={t('steps.admin-creds.username-input-field-label')}
              name={adminUsernameField}
              data-testid="username-field"
            >
              <InputBox
                autoFocus
                disabled={pending}
                type="text"
                size="small"
                placeholder=""
                style={{
                  width: '496px',
                  color: '#333',
                }}
                onChange={event =>
                  setIsSubmitEnabled(event.target.value.length > 0)
                }
              />
            </Form.Item>
          </Paragraph>
          <Paragraph className={classes.button}>
            <Button
              htmlType="submit"
              label={t('steps.admin-creds.next-btn')}
              disabled={!isSubmitEnabled || pending}
              type="primary"
              size="large"
              astraButtonType={['major']}
              data-testid="submit-btn"
            />
          </Paragraph>
        </Form>
        <Paragraph className="primary-body">
          {t('steps.admin-creds.label-2.paragraph-1')}
        </Paragraph>
        <Paragraph className="primary-body">
          {t('steps.admin-creds.label-2.paragraph-2')}
          <br />
          <p data-testid="link-to-admin-ui">{adminUiUrl}</p>
        </Paragraph>
      </Content>
    </SetupStep>
  );
};

export default withTracing(InitialAdminCredsStep);
