// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type React from 'react';
import { Typography, Layout } from 'antd';
import clsx from 'clsx';
import { Button } from '@imprivata-cloud/astra-ui-component-library';
import SetupStep from '../../components/SetupStep';
import SetupStepHeader from '../../components/SetupStepHeader';

import backgroundImage from '../../assets/astra_setup_a.svg';
import classes from './steps.module.less';
import { useStartSetupRequest } from '../../hooks/startSetupHooks';
import { Link } from 'react-router-dom';
import { ADD_TRUSTED_LOCATION_ROUTE } from '../../constants/routes';
import withTracing from '../../tracing/withTracing';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;
const { Content } = Layout;

const GetStartedStep: React.FC = function GetStartedStep() {
  const { t } = useTranslation();

  useStartSetupRequest();

  return (
    <SetupStep
      data-testid="step-get-started"
      className={classes.getStarted}
      rightPartBackground={backgroundImage}
    >
      <SetupStepHeader title={t('steps.get-started.title-label')} />
      <Content data-testid="step-description">
        <Paragraph className={clsx('subtitle', classes.label_1)}>
          {t('steps.get-started.label')}
        </Paragraph>
        <Paragraph className="primary-body">
          <ul className={classes.styledList}>
            <li>{t('steps.get-started.list-item-1')}</li>
            <li>{t('steps.get-started.list-item-2')}</li>
          </ul>
        </Paragraph>
        <Paragraph className={classes.button}>
          <Link to={ADD_TRUSTED_LOCATION_ROUTE}>
            <Button
              autoFocus
              label={t('steps.get-started.next-btn')}
              type="primary"
              size="large"
              astraButtonType={['major']}
              data-testid="proceed-btn"
            />
          </Link>
        </Paragraph>
      </Content>
    </SetupStep>
  );
};

export default withTracing(GetStartedStep);
