// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import tracingSetup from './tracing/tracingSetup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import packageJson from '../package.json';

import './styles/index.less';
import './index.less';

tracingSetup(packageJson.name);
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
